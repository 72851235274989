import {
    Button,
    Container,
    ContainerProps,
    styled,
    Theme,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    useMediaQuery,
} from '@mui/material';
import { ViewOptions } from '../utils/types';
import { Auth } from 'firebase/auth';
import { ref, set } from 'firebase/database';
import {
    useAuth,
    useDatabase,
    useDatabaseObjectData,
    useSigninCheck,
} from 'reactfire';
import React, { useEffect } from 'react';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import WeekendIcon from '@mui/icons-material/Weekend';
import BookIcon from '@mui/icons-material/Book';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDroppable } from '@dnd-kit/core';

export const signOut = (auth: Auth) =>
    auth.signOut().then(() => console.log('signed out'));

export function HeaderMenu() {
    const db = useDatabase();
    const auth = useAuth();

    const { status, data: creds, error } = useSigninCheck();
    useEffect(() => {
        if (error) location.reload();
    }, [error]);
    if (['loading', 'error'].includes(status) || !creds.signedIn) return <></>;

    const viewOptionsRef = ref(db, `${creds?.user?.uid}/view-options`);
    const { status: viewOptionsStatus, data: viewOptions } =
        useDatabaseObjectData<ViewOptions>(viewOptionsRef);
    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md'),
    );
    const { setNodeRef, isOver } = useDroppable({
        id: 'backlog',
        data: {
            dayPath: `${creds.user.uid}/backlog`,
        },
    });

    const handleViewOptions = async (
        _evt: React.MouseEvent<HTMLElement>,
        newFormats: string[],
    ) => {
        if (viewOptionsStatus !== 'success') return;
        await set(
            viewOptionsRef,
            newFormats.reduce((acc, f) => ({ ...acc, [f]: true }), {}),
        );
    };

    return (
        <ButtonContainerStyled>
            <ToggleButtonGroup
                value={Object.keys(viewOptions ?? {})}
                onChange={handleViewOptions}
                aria-label="view options"
                sx={{ margin: '4px' }}
            >
                {!smallScreen && (
                    <Tooltip title="Toggle Split View">
                        <ToggleButton
                            value="split"
                            aria-label="split"
                            selected={viewOptions?.split}
                            size={'small'}
                            sx={(theme) => ({
                                backgroundColor: viewOptions?.split
                                    ? `${theme.palette.primary.main} !important`
                                    : 'inherit',
                            })}
                        >
                            <SplitscreenIcon />
                        </ToggleButton>
                    </Tooltip>
                )}

                {!smallScreen && (
                    <Tooltip title="Toggle Weekend">
                        <ToggleButton
                            value="weekend"
                            aria-label={'weekend'}
                            selected={viewOptions?.weekend}
                            size={'small'}
                            sx={(theme) => ({
                                backgroundColor: viewOptions?.weekend
                                    ? `${theme.palette.primary.main} !important`
                                    : 'inherit',
                            })}
                        >
                            <WeekendIcon />
                        </ToggleButton>
                    </Tooltip>
                )}
                <Tooltip title="Toggle Backlog">
                    <ToggleButton
                        ref={setNodeRef}
                        value="backlog"
                        aria-label={'backlog'}
                        selected={viewOptions?.backlog}
                        size={'small'}
                        sx={(theme) => ({
                            backgroundColor: viewOptions?.backlog
                                ? `${theme.palette.primary.main} !important`
                                : 'inherit',
                            border: isOver ? '2px solid white' : '',
                        })}
                    >
                        <BookIcon />
                    </ToggleButton>
                </Tooltip>
            </ToggleButtonGroup>
            <Tooltip title="Sign Out">
                <Button
                    variant="contained"
                    color="primary"
                    size={'small'}
                    onClick={() => signOut(auth)}
                    sx={{ margin: '5px 10px' }}
                >
                    <LogoutIcon />
                </Button>
            </Tooltip>
        </ButtonContainerStyled>
    );
}

const ButtonContainerStyled = styled(Container)<ContainerProps>(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '16px 24px',
    position: 'absolute',
    right: 0,
    width: 'auto',
}));
