import React, {
    Dispatch,
    KeyboardEventHandler,
    memo,
    SetStateAction,
} from 'react';
import { TextField } from '@mui/material';

interface AddTaskProps {
    inputValue: string;
    onInputChange: Dispatch<SetStateAction<string>>;
    onInputKeyPress: KeyboardEventHandler<HTMLInputElement>;
}

const AddTask = memo<AddTaskProps>(function AddTodo(props) {
    return (
        <TextField
            value={props.inputValue}
            onChange={(v) => props.onInputChange(v.target.value)}
            inputProps={{
                onKeyPress: props.onInputKeyPress,
            }}
            fullWidth
            multiline
            variant="outlined"
            size="small"
            autoFocus
            margin="dense"
        />
    );
});
export default AddTask;
