import {
    Avatar,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Container,
    Link,
    Typography,
} from '@mui/material';
import { Auth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import React, { ReactNode } from 'react';
import { useAuth, useSigninCheck } from 'reactfire';
import { Logo } from '../Logo';

const signIn = async (auth: Auth) => {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider);
};

const SignInForm = () => {
    const auth = useAuth();

    return (
        <Container component="main" maxWidth="md">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '2rem',
                }}
            >
                <Avatar sx={{ background: 'none' }}></Avatar>
                <Typography
                    variant={'h2'}
                    color="text.secondary"
                    sx={{ textAlign: 'center', fontWeight: 'bold' }}
                >
                    Weekly Tasker
                </Typography>

                <Button variant="contained" onClick={() => signIn(auth)}>
                    Sign in w/ Google
                </Button>
                <Logo color={'primary'} />
                <Link
                    href={'https://weeklytasker.app/privacy.html'}
                    sx={{
                        fontSize: '0.75rem',
                        textAlign: 'center',
                        fontFamily: 'Arial',
                        fontWeight: 'bold',
                        fontVariant: 'small-caps',
                    }}
                >
                    privacy policy
                </Link>
            </Box>
        </Container>
    );
};

export const AuthWrapper = ({ children }: { children: ReactNode }) => {
    const { status, data } = useSigninCheck();
    if (status === 'loading') {
        return (
            <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return data.signedIn && data.user ? <>{children}</> : <SignInForm />;
};
