import { Box } from '@mui/material';
import React from 'react';
import { useDateStore } from '../utils/stores';
import { blueGrey } from '@mui/material/colors';
import dayjs from 'dayjs';

export const WeekTicks = () => {
    const { date } = useDateStore();
    const days = [];
    const start = date.startOf('week');
    const today = dayjs();
    for (let i = 0; i < 7; i++) {
        const day = start.clone().add(i, 'days');
        const isDate = day.isSame(date, 'day');
        const isToday = day.isSame(today, 'day');
        days.push(
            <Box
                key={`weektick_${i}`}
                sx={(theme) => ({
                    width: 'calc(100vw / 7)',
                    height: '4px',
                    backgroundColor: isDate
                        ? theme.palette.primary.dark
                        : isToday
                        ? 'transparent'
                        : blueGrey['100'],
                    border: isDate
                        ? '1px solid transparent'
                        : isToday
                        ? `1px solid ${blueGrey['100']}`
                        : '1px solid transparent',
                })}
            />,
        );
    }
    return (
        <Box
            sx={{
                display: {
                    xs: 'flex',
                    md: 'none',
                },
                position: 'absolute',
                bottom: '-0.7em',
                flexDirection: 'row',
                width: '100vw',
                justifyContent: 'space-between',
            }}
        >
            {days}
        </Box>
    );
};
