import React from 'react';
import { List } from '@mui/material';
import ListItem from './TaskListItem';
import { Task } from '../../utils/types';
import { Droppable } from './Droppable';
import dayjs from 'dayjs';

interface TaskListProps {
    items: Task[];
    dayPath: string;
    style: object;
    date: dayjs.Dayjs | null;
}

const TaskList = (props: TaskListProps) => {
    const items = props.items.filter((t) => t.text);
    return (
        <List
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100% - 1em)',
            }}
        >
            {items.map((task, idx) => (
                <div key={`Item.${task.id}`}>
                    <Droppable
                        id={`${props.dayPath}/${idx}`}
                        data={{
                            dayPath: props.dayPath,
                            position: task.position - 0.001,
                        }}
                    />
                    <ListItem
                        {...task}
                        divider={idx !== items.length - 1}
                        path={`${props.dayPath}/${task.id}`}
                        dayPath={props.dayPath}
                        count={items.length}
                        date={props.date}
                    />
                </div>
            ))}

            <Droppable
                id={`${props.dayPath}/${items.length}`}
                key={`Item.${props.dayPath}/${items.length}`}
                data={{ dayPath: props.dayPath, position: items.length * 10 }}
                fillHeight
            />
        </List>
    );
};
export default TaskList;
