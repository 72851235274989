import React, { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Dialog,
    Link,
    Tab,
    Theme,
    useMediaQuery,
} from '@mui/material';
import faqContent from './faq-content';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TabContext, TabList, TabPanel } from '@mui/lab';

export const Faq = () => {
    const [open, setOpen] = useState(false);
    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md'),
    );
    const [value, setValue] = React.useState<'web' | 'mobile'>(
        smallScreen ? 'mobile' : 'web',
    );

    const handleChange = (
        _: React.SyntheticEvent,
        newValue: 'web' | 'mobile',
    ) => {
        setValue(newValue);
    };

    return (
        <>
            <Link href="#" onClick={() => setOpen(true)}>
                FAQ
            </Link>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullScreen={smallScreen}
                scroll={'paper'}
            >
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={handleChange}
                            aria-label="faq-tabs"
                            centered
                            variant="fullWidth"
                        >
                            <Tab label="Web" value="web" />
                            <Tab label="Mobile" value="mobile" />
                        </TabList>
                    </Box>
                    <TabPanel value="web" sx={{ fontFamily: 'sans-serif' }}>
                        {faqContent.web.map(
                            ({
                                title,
                                content,
                            }: {
                                title: string;
                                content: string;
                            }) => (
                                <Accordion key={title}>
                                    <AccordionSummary
                                        expandIcon={
                                            <ExpandMoreIcon
                                                sx={{ color: 'white' }}
                                            />
                                        }
                                        sx={(theme) => ({
                                            backgroundColor:
                                                theme.palette.primary.light,
                                            color: 'white',
                                        })}
                                    >
                                        {title}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {content}
                                    </AccordionDetails>
                                </Accordion>
                            ),
                        )}
                    </TabPanel>

                    <TabPanel value="mobile" sx={{ fontFamily: 'sans-serif' }}>
                        {faqContent.mobile.map(
                            ({
                                title,
                                content,
                            }: {
                                title: string;
                                content: string;
                            }) => (
                                <Accordion key={title}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        sx={(theme) => ({
                                            backgroundColor:
                                                theme.palette.primary.light,
                                            color: 'white',
                                        })}
                                    >
                                        {title}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {content}
                                    </AccordionDetails>
                                </Accordion>
                            ),
                        )}
                    </TabPanel>
                </TabContext>
                <Button onClick={() => setOpen(false)} variant={'contained'}>
                    Close
                </Button>
            </Dialog>
        </>
    );
};
