import React, { useRef } from 'react';
import {
    DateCalendar,
    LocalizationProvider,
    PickersDay,
    PickersDayProps,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDateStore, useDragStore } from '../utils/stores';
import dayjs, { Dayjs } from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled, Theme } from '@mui/material/styles';
import { Button, Dialog, Popover, useMediaQuery } from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { useDroppable } from '@dnd-kit/core';
import { useDatabase, useSigninCheck } from 'reactfire';
import { moveTask } from '../utils/firebase';

dayjs.extend(isBetweenPlugin);

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
    dayIsBetween: boolean;
    isFirstDay: boolean;
    isLastDay: boolean;
}

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' &&
        prop !== 'isFirstDay' &&
        prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '10%',
        borderBottomLeftRadius: '10%',
    }),
    ...(isLastDay && {
        borderTopRightRadius: '10%',
        borderBottomRightRadius: '10%',
    }),
})) as React.ComponentType<CustomPickerDayProps>;

function Day(props: PickersDayProps<Dayjs> & { selectedDay?: Dayjs | null }) {
    const { day, selectedDay, ...other } = props;

    if (selectedDay == null) {
        return <PickersDay day={day} {...other} />;
    }

    const start = selectedDay.startOf('week');
    const end = selectedDay.endOf('week');

    const dayIsBetween = day.isBetween(start, end, null, '[]');
    const isFirstDay = day.isSame(start, 'day');
    const isLastDay = day.isSame(end, 'day');

    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
            dayIsBetween={dayIsBetween}
            isFirstDay={isFirstDay}
            isLastDay={isLastDay}
        />
    );
}

export const DateSelector = () => {
    const { date, setDate } = useDateStore();
    const db = useDatabase();
    const { calendar, setCalendar } = useDateStore();
    const dragContext = useDragStore();
    const buttonRef = useRef<HTMLElement | null>(null);
    const { status, data: creds } = useSigninCheck();
    if (['loading', 'error'].includes(status) || !creds.signedIn) return <></>;

    const userId = creds?.user?.uid;
    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md'),
    );
    const { setNodeRef, isOver } = useDroppable({
        id: 'calendar',
        data: {},
    });

    const handleClick = () => setCalendar({ open: true, context: null });
    const handleClose = async () => {
        setCalendar({ open: false, context: null });
    };
    const handleChange = async (newValue: dayjs.Dayjs | null) => {
        if (calendar.context) {
            dragContext.toPosition = -999;
            dragContext.toDayPath = `${userId}/${newValue!.format('YY_MM_DD')}`;
            await moveTask(db, dragContext, calendar.context.copy);
        } else setDate(newValue!);
        handleClose();
    };

    const id = calendar ? 'date-popover' : undefined;

    const createCalendar = () => (
        <>
            <DateCalendar
                autoFocus={false}
                showDaysOutsideCurrentMonth={true}
                fixedWeekNumber={6}
                yearsPerRow={3}
                value={date}
                onChange={handleChange}
                slots={
                    smallScreen
                        ? {}
                        : {
                              day: Day,
                          }
                }
                slotProps={
                    smallScreen
                        ? {}
                        : {
                              day: {
                                  selectedDay: date,
                              } as never,
                          }
                }
            />
        </>
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Button
                aria-describedby={id}
                variant="text"
                onClick={() => setDate(date.subtract(1, 'week'))}
                sx={{
                    marginLeft: '2em',
                    display: { md: 'inherit', xs: 'none' },
                }}
            >
                <KeyboardArrowLeftOutlinedIcon />
            </Button>
            <Button
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
                sx={{
                    marginRight: '1em',
                    border: isOver ? '2px solid white' : '',
                }}
                ref={(el) => {
                    setNodeRef(el);
                    buttonRef.current = el;
                }}
            >
                <EventNoteIcon />
            </Button>
            <Button
                variant="contained"
                onClick={() => {
                    setDate(dayjs().startOf('day'));
                }}
            >
                TODAY
            </Button>
            <Button
                aria-describedby={id}
                variant="text"
                onClick={() => setDate(date.add(1, 'week'))}
                sx={{ display: { md: 'inherit', xs: 'none' } }}
            >
                <KeyboardArrowRightOutlinedIcon />
            </Button>

            {smallScreen && (
                <Dialog open={calendar.open} onClose={handleClose}>
                    {createCalendar()}
                </Dialog>
            )}

            {!smallScreen && (
                <Popover
                    id={id}
                    anchorEl={buttonRef.current}
                    open={calendar.open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    {createCalendar()}
                </Popover>
            )}
        </LocalizationProvider>
    );
};
