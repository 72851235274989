import React, { memo, useState } from 'react';
import {
    Checkbox,
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
    Theme,
    useMediaQuery,
} from '@mui/material';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { Task } from '../../utils/types';
import { ref, remove, update } from 'firebase/database';
import { useDatabase } from 'reactfire';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useDraggable } from '@dnd-kit/core';
import dayjs from 'dayjs';

export interface TaskListItemProps extends Task {
    path: string;
    divider: boolean;
    dayPath: string;
    count: number;
    date: dayjs.Dayjs | null;
}

const TaskListItem = memo<TaskListItemProps>(function TaskListItem(props) {
    const db = useDatabase();
    const [editing, setEditing] = useState(false);
    const [inputValue, setValue] = useState('');
    const context = {
        text: props.text,
        complete: props.complete,
        dayPath: props.dayPath,
        path: props.path,
    };

    const { setNodeRef, isDragging, listeners, attributes } = useDraggable({
        id: props.id,
        data: context,
    });

    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md'),
    );

    const { path } = props;

    const removeTask = async () => {
        await remove(ref(db, path));
    };

    const checkTask = async (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        evt.preventDefault();
        await update(ref(db, path), {
            complete: !props.complete,
            position: props.count * 10,
        });
    };

    const editTask = async (value: string) => {
        await update(ref(db, path), { text: value });
    };

    const clearInputAndEdit = async () => {
        await editTask(inputValue);
        setValue('');
    };

    const style: { color?: string; textDecoration?: string } = {};
    if (props.complete) {
        style.color = 'grey';
        style.textDecoration = 'line-through';
    }

    return (
        <ListItem
            dense={true}
            divider={props.divider}
            sx={{
                margin: 0,
                padding: 0,
                whiteSpace: 'normal',
                overflowWrap: 'normal',
                '&:hover': {
                    '& + .MuiListItemSecondaryAction-root': {
                        visibility: 'visible !important',
                    },
                },
            }}
        >
            {editing ? (
                <TextField
                    value={inputValue}
                    onChange={(v) => setValue(v.target.value)}
                    multiline
                    inputProps={{
                        onKeyPress: async (evt) => {
                            if (evt.key === 'Enter') {
                                await editTask(inputValue);
                                setEditing(false);
                            }
                            if (evt.key === 'Escape') {
                                setEditing(false);
                            }
                        },
                        onBlur: async () => {
                            await editTask(inputValue);
                            setEditing(false);
                        },
                        sx: { fontSize: '0.8em', padding: '0 1em' },
                    }}
                    fullWidth
                    autoFocus
                    size={'small'}
                    variant={'standard'}
                    onBlur={clearInputAndEdit}
                />
            ) : (
                <>
                    <IconButton
                        ref={smallScreen ? setNodeRef : undefined}
                        disableFocusRipple
                        disableRipple
                        {...(smallScreen ? listeners : {})}
                        {...(smallScreen ? attributes : {})}
                        sx={{
                            margin: 0,
                            padding: 0,
                            display: { md: 'none', xs: 'inherit' },
                        }}
                    >
                        <DragIndicatorIcon
                            fontSize={'small'}
                            sx={{ margin: 0, padding: 0 }}
                        />
                    </IconButton>
                    <Checkbox
                        checked={props.complete}
                        onClick={checkTask}
                        disableRipple
                        tabIndex={-1}
                        color="primary"
                        size={'small'}
                    />
                    <ListItemText
                        primary={props.text}
                        {...(!smallScreen ? listeners : {})}
                        {...(!smallScreen ? attributes : {})}
                        ref={!smallScreen ? setNodeRef : null}
                        sx={{
                            ...style,
                            color: isDragging ? 'lightgray' : 'inherit',
                            whiteSpace: 'pre-line',
                            cursor: 'grab',
                        }}
                        onDoubleClick={async () => {
                            setValue(props.text);
                            setEditing(true);
                        }}
                    />
                </>
            )}
            {props.complete && !editing && (
                <ListItemSecondaryAction
                    sx={{
                        visibility: 'hidden',
                        '&:hover': {
                            visibility: 'visible',
                        },
                        marginRight: '-1em',
                    }}
                >
                    <IconButton aria-label="Delete" onClick={removeTask}>
                        <DeleteOutlined fontSize="small" />
                    </IconButton>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
});

export default TaskListItem;
