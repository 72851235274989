import React, { ReactNode } from 'react';
import { Paper, Typography } from '@mui/material';
import { Header } from './header/Header';
import { Faq } from './Faq';

interface LayoutProps {
    children: ReactNode;
}

const Layout = (props: LayoutProps) => {
    return (
        <Paper
            elevation={0}
            style={{ padding: 0, margin: 0, backgroundColor: '#fafafa' }}
        >
            <Header />
            {props.children}

            <Typography
                variant="caption"
                sx={{
                    position: 'fixed',
                    width: 'auto',
                    bottom: 5,
                    left: 10,
                    height: '1.5em',
                }}
            >
                <Faq /> — Created by blake for rachel.
            </Typography>
        </Paper>
    );
};

export default Layout;
