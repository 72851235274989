import { Database, push, ref, remove, set } from 'firebase/database';
import { DragContext } from './types';

export const firebaseConfig = {
    apiKey: 'AIzaSyClTCDUcXqa-glRt3TDeDzWfZedPIW4jrU',
    authDomain: 'weeklytasker.app',
    databaseURL: 'https://weekly-tasker.firebaseio.com',
    projectId: 'weekly-tasker',
    storageBucket: 'weekly-tasker.appspot.com',
    messagingSenderId: '197184128386',
    appId: '1:197184128386:web:3a2f62b22e6584a160bb9b',
    measurementId: 'G-8RLG7G6RRM',
};

export async function moveTask(
    db: Database,
    context: Omit<DragContext, 'toPath' | 'fromDayPath' | 'fromPosition'>,
    copy: boolean,
) {
    const promises = [
        set(push(ref(db, context.toDayPath)), {
            text: context.text,
            complete: context.complete,
            position: context.toPosition,
        }),
    ];

    if (!copy) promises.push(remove(ref(db, context.fromPath)));
    await Promise.all(promises);
}
