import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect } from 'react';
import { ref } from 'firebase/database';
import { useDatabase, useDatabaseObjectData, useSigninCheck } from 'reactfire';
import { ViewOptions } from '../utils/types';
import { DayCards } from './DayCards';
import { Theme, useMediaQuery } from '@mui/material';

export const DayGrid = () => {
    const db = useDatabase();
    const { status, data: creds, error } = useSigninCheck();
    useEffect(() => {
        if (error) location.reload();
    }, [error]);
    if (['loading', 'error'].includes(status) || !creds.signedIn) return <></>;

    const userId = creds?.user?.uid;
    const viewOptionsRef = ref(db, `${userId}/view-options`);
    const { status: viewOptionsStatus, data: viewOptions } =
        useDatabaseObjectData<ViewOptions>(viewOptionsRef);

    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md'),
    );
    if (viewOptionsStatus === 'loading') return <></>;

    let columns = 5;
    if (viewOptions?.weekend) columns += 2;
    if (viewOptions?.backlog) columns += 1;

    if (smallScreen) columns = 1;

    return (
        <Grid
            container
            columns={columns}
            sx={{
                marginTop: '5em',
                minHeight: 'calc(100svh - 5em)',
                maxHeight: 'calc(100svh - 5em)',
                paddingBottom: '1.5em',
            }}
            disableEqualOverflow
        >
            <DayCards viewOptions={viewOptions} userId={userId} />
        </Grid>
    );
};
