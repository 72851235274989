import React from 'react';

export const Logo = ({ color }: { color: 'white' | 'primary' }) => {
    const primary = new URL('../public/icon-512.png', import.meta.url);
    const white = new URL('../public/icon-512-white.png', import.meta.url);

    return (
        <img
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            src={color === 'white' ? white : primary}
            alt={'weekly-tasker-logo'}
            width={64}
            height={64}
        />
    );
};
