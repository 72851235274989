import React, { ReactNode, useRef, useState } from 'react';
import { ViewOptions } from '../utils/types';
import Day from './Day';
import dayjs from 'dayjs';
import { Theme, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useDateStore } from '../utils/stores';
import { useSwipeable } from 'react-swipeable';
import { useDndMonitor } from '@dnd-kit/core';

interface DayCardsProps {
    viewOptions: ViewOptions;
    userId: string;
}

const clamp = (num: number, min: number, max: number) =>
    Math.min(Math.max(num, min), max);

export function DayCards({ viewOptions, userId }: DayCardsProps) {
    const days: ReactNode[] = [];
    const { weekend, backlog, split } = viewOptions ?? {};
    const today = dayjs().startOf('day');
    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md'),
    );

    const [dragging, setDragging] = useState(false);
    useDndMonitor({
        onDragStart: () => setDragging(true),
        onDragEnd: () => setTimeout(() => setDragging(false), 100),
        onDragCancel: () => setTimeout(() => setDragging(false), 100),
    });

    const swipeRef = useRef<HTMLElement | null>(null);

    const { date, setDate } = useDateStore();
    const handlers = useSwipeable({
        onSwipedLeft: (evt) => {
            if (evt.deltaX > -100 || dragging) return;
            setDate(date.add(1, 'day'));
        },
        onSwipedRight: (evt) => {
            if (evt.deltaX < 100 || dragging) return;
            setDate(date.subtract(1, 'day'));
        },
        onSwipedUp: (evt) => {
            if (evt.deltaY > -150 || dragging) return;
            setDate(date.add(1, 'week'));
        },
        onSwipedDown: (evt) => {
            if (evt.deltaY < 150 || dragging) return;
            setDate(date.subtract(1, 'week'));
        },
        onSwiping: (evt) => {
            if (!swipeRef.current || dragging) return;
            swipeRef.current.style.transition =
                'transform 0.1s linear, opacity 0.1s linear';
            switch (evt.dir) {
                case 'Left':
                    swipeRef.current.style.transform = `translate(${
                        (evt.deltaX + 10) / 2
                    }px, 0)`;
                    swipeRef.current.style.opacity = `${clamp(
                        1 - Math.abs(evt.deltaX + 40) / 100,
                        0,
                        1,
                    )}`;
                    break;
                case 'Right':
                    swipeRef.current.style.transform = `translate(${
                        (evt.deltaX - 10) / 2
                    }px, 0)`;
                    swipeRef.current.style.opacity = `${clamp(
                        1 - Math.abs(evt.deltaX - 40) / 100,
                        0,
                        1,
                    )}`;
                    break;
                case 'Up':
                    swipeRef.current.style.transform = `translate(0, ${
                        (evt.deltaY - 10) / 2
                    }px)`;
                    swipeRef.current.style.opacity = `${clamp(
                        1 - Math.abs(evt.deltaY - 40) / 150,
                        0,
                        1,
                    )}`;
                    break;
                case 'Down':
                    swipeRef.current.style.transform = `translate(0, ${
                        (evt.deltaY + 10) / 2
                    }px)`;
                    swipeRef.current.style.opacity = `${clamp(
                        1 - Math.abs(evt.deltaY + 40) / 150,
                        0,
                        1,
                    )}`;
                    break;
            }
        },
        onSwiped: () => {
            if (!swipeRef.current) return;
            swipeRef.current.style.transform = 'translate(0)';
            swipeRef.current.style.opacity = '1';
            swipeRef.current.style.transition =
                'transform 0.5s ease-in-out, opacity 0.5s ease-in';
        },
        delta: 10,
    });

    const count = weekend ? 7 : 5;
    if (smallScreen) {
        if (backlog)
            days.push(
                <Grid key={'backlog'} xs={1}>
                    <Day
                        date={null}
                        userId={userId}
                        isToday={false}
                        style={{
                            minHeight: 'calc(100% - 2em)',
                            maxHeight: 'calc(100% - 2em)',
                        }}
                    />
                </Grid>,
            );
        else
            days.push(
                <Grid
                    {...handlers}
                    ref={(el) => {
                        handlers.ref(el);
                        swipeRef.current = el;
                    }}
                    key={date.toString()}
                    xs={1}
                >
                    <Day
                        date={date}
                        userId={userId}
                        isToday={today.isSame(date, 'day')}
                        style={{
                            minHeight: 'calc(100% - 2em)',
                            maxHeight: 'calc(100% - 2em)',
                        }}
                    />
                </Grid>,
            );
    } else {
        for (let i = 0; i < count; i++) {
            const dayValue = date.day(weekend ? i : i + 1);
            days.push(
                <Grid key={dayValue.toString()} xs={1}>
                    <Day
                        date={dayValue}
                        userId={userId}
                        isToday={dayValue.isSame(today)}
                        style={{
                            minHeight: split
                                ? 'calc(50svh - 6em)'
                                : 'calc(100svh - 9.5em)',
                            maxHeight: split
                                ? 'calc(50svh - 6em)'
                                : 'calc(100svh - 9.5em)',
                        }}
                    />
                </Grid>,
            );
        }

        if (backlog) {
            days.push(
                <Grid key={'backlog'} xs={1}>
                    <Day
                        date={null}
                        userId={userId}
                        isToday={false}
                        style={{
                            minHeight: split
                                ? 'calc(50svh - 6em)'
                                : 'calc(100svh - 9.5em)',
                            maxHeight: split
                                ? 'calc(50svh - 6em)'
                                : 'calc(100svh - 9.5em)',
                        }}
                    />
                </Grid>,
            );
        }

        if (split) {
            for (let i = 0; i < count; i++) {
                const dayValue = date.day(weekend ? i : i + 1).add(7, 'day');
                days.push(
                    <Grid xs={1} key={dayValue.toString()}>
                        <Day
                            date={dayValue}
                            userId={userId}
                            isToday={dayValue.isSame(today)}
                            style={{
                                minHeight: 'calc(50svh - 6em)',
                                maxHeight: 'calc(50svh - 6em)',
                            }}
                        />
                    </Grid>,
                );
            }
        }
    }

    return days;
}
