import {
    AppBar,
    AppBarProps,
    Toolbar,
    ToolbarProps,
    Typography,
} from '@mui/material';
import React from 'react';
import { DateSelector } from './DateSelector';
import { styled } from '@mui/material/styles';
import { HeaderMenu } from './HeaderMenu';
import { Logo } from '../Logo';
import { WeekTicks } from './WeekTicks';

export const Header = () => {
    return (
        <AppBarStyled position="static">
            <ToolbarStyled>
                <Typography
                    variant="h5"
                    color="inherit"
                    sx={{ display: { md: 'inherit', xs: 'none' } }}
                >
                    <Logo color={'white'} />
                </Typography>
                <DateSelector />
                <HeaderMenu />
            </ToolbarStyled>
            <WeekTicks />
        </AppBarStyled>
    );
};

const AppBarStyled = styled(AppBar)<AppBarProps>(({ theme }) => ({
    height: '4em',
    backgroundColor: theme.palette.primary.light,
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9,
}));

const ToolbarStyled = styled(Toolbar)<ToolbarProps>(() => ({
    height: '4em',
    width: '100vw',
}));
