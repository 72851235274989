import React, { useState } from 'react';
import dayjs from 'dayjs';
import { IconButton, Paper, Popover, Stack, Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddTask from './tasks/AddTask';

interface DayHeaderProps {
    date: dayjs.Dayjs | null;
    onAddTask: (value: string, complete: boolean) => void;
    dayStatus: 'loading' | 'error' | 'success';
    dayPath: string;
}

export function DayHeader(props: DayHeaderProps) {
    const [inputValue, setValue] = useState('');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const day = props.date?.format('ddd') ?? 'Backlog';
    const open = Boolean(anchorEl);
    const id = open ? 'add-task-popper' : undefined;
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const onInputKeyPress = async (
        evt: React.KeyboardEvent<HTMLInputElement>,
    ) => {
        if (evt.key === 'Enter' && !evt.shiftKey) {
            props.onAddTask(inputValue, false);
            setValue('');
            setAnchorEl(null);
        }
        if (evt.key === 'Escape') {
            setValue('');
            setAnchorEl(null);
        }
    };
    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <div
                    style={{
                        borderLeft: `2px solid ${blueGrey[100]}`,
                        display: 'inline-flex',
                    }}
                >
                    <IconButton
                        onClick={handleClick}
                        sx={{
                            width: '1.25rem',
                            height: '1.25rem',
                            margin: '0.3rem 0.5rem',
                            display: { md: 'inherit', xs: 'none' },
                        }}
                    >
                        <AddCircleIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        sx={{ paddingLeft: { xs: '1em', lg: '0' } }}
                    >
                        {day}
                    </Typography>
                </div>
                <Typography variant="h6" sx={{ color: 'grey' }}>
                    {props.date?.format('MMM D') ?? ''}
                </Typography>
            </Stack>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Paper
                    elevation={5}
                    sx={{ padding: '1em', width: '400px', maxWidth: '80vw' }}
                >
                    <AddTask
                        inputValue={inputValue}
                        onInputChange={setValue}
                        onInputKeyPress={onInputKeyPress}
                    />
                </Paper>
            </Popover>
        </>
    );
}
