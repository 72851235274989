const faqContent: Record<
    'web' | 'mobile',
    { title: string; content: string }[]
> = {
    web: [
        {
            title: 'Create new task',
            content:
                'Click the little plus button at the top of the day\'s card',
        },
        {
            title: 'Edit task',
            content: 'Double click the text of the task',
        },
        {
            title: 'Mark task complete',
            content: 'Click the checkbox to the left of the task',
        },
        {
            title: 'Delete a task',
            content:
                'Once a task is marked as complete, hover over the task and click the trash icon',
        },
        {
            title: 'Move task to another day on screen',
            content:
                'Click and drag the text of the task to the same or a different day at any position',
        },
        {
            title: 'Move task to another day off screen',
            content:
                'Click and drag the text of the task to the calendar, when the calendar opens select the day you want to move the task to',
        },
        {
            title: 'Copy a task',
            content:
                'Click and drag the text of the task while holding the `shift` key',
        },
    ],
    mobile: [
        {
            title: 'Move to different days',
            content:
                'Swipe left to go the next day, swipe right to the previous day, up to the next week, and down to the previous week',
        },
        {
            title: 'Create new task',
            content: 'Tap the plus button at the bottom',
        },
        {
            title: 'Edit task',
            content: 'Double tap the text of the task',
        },
        {
            title: 'Mark task complete',
            content: 'Tap the checkbox to the left of the task',
        },
        {
            title: 'Delete a task',
            content:
                'Once a task is marked as complete, tap the text then click the trash icon',
        },
        {
            title: 'Move task to another day on screen',
            content: 'Tap and drag the drag icon of the task to any position',
        },
        {
            title: 'Move task to another day off screen',
            content:
                'Tap and drag the drag icon of the task to the calendar, when the calendar opens select the day you want to move the task to',
        },
        {
            title: 'Move task to backlog',
            content:
                'Tap and drag the drag icon of the task to the backlog icon',
        },
        {
            title: 'Copy a task',
            content: 'Currently unavailable on mobile',
        },
    ],
};

export default faqContent;
