import React from 'react';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { AuthWrapper } from './wrappers/AuthWrapper';
import { FirebaseProviderWrapper } from './wrappers/FirebaseProviderWrapper';
import { firebaseConfig } from './utils/firebase';
import { FirebaseAppProvider } from 'reactfire';
import { DayGrid } from './days/DayGrid';
import Layout from './Layout';
import { DndWrapper } from './wrappers/DndWrapper';

dayjs.extend(weekOfYear);

export default function App() {
    return (
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <FirebaseProviderWrapper>
                <AuthWrapper>
                    <DndWrapper>
                        <Layout>
                            <DayGrid />
                        </Layout>
                    </DndWrapper>
                </AuthWrapper>
            </FirebaseProviderWrapper>
        </FirebaseAppProvider>
    );
}
