import React, { ReactNode, useEffect, useState } from 'react';
import {
    DndContext,
    DragEndEvent,
    DragStartEvent,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { ref, update } from 'firebase/database';
import { useDatabase } from 'reactfire';
import { useDateStore, useDragStore } from '../utils/stores';
import { moveTask } from '../utils/firebase';
import { Theme, useMediaQuery } from '@mui/material';

export const DndWrapper = ({ children }: { children: ReactNode }) => {
    const db = useDatabase();

    const { setCalendar } = useDateStore();
    const { setContext, ...dragContext } = useDragStore();
    const touchSensor = useSensor(TouchSensor);
    const [dragging, setDragging] = useState(false);
    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md'),
    );

    useEffect(() => {
        if (dragging) document.body.style.cursor = 'grabbing';
        else document.body.style.cursor = 'inherit';
    }, [dragging]);

    const sensors = useSensors(touchSensor);
    const onDragEnd = async (evt: DragEndEvent) => {
        const toData = evt.over?.data.current;
        const fromData = evt.active?.data.current;
        const copy = (evt.activatorEvent as PointerEvent)?.shiftKey;
        setDragging(false);
        if (!toData || !fromData) return;
        if (evt.over?.id === 'calendar') {
            return setCalendar({
                open: true,
                context: { toData, fromData, copy },
            });
        }

        if (evt.over?.id === 'backlog') {
            dragContext.toPosition = -999;
            dragContext.toDayPath = toData.dayPath;
            return moveTask(db, dragContext, copy);
        }

        if (toData.dayPath === fromData.dayPath) {
            await update(ref(db, fromData.path), { position: toData.position });
            return;
        }
        dragContext.toPosition = toData.position;
        dragContext.toDayPath = toData.dayPath;
        await moveTask(db, dragContext, copy);
    };

    const onDragStart = (evt: DragStartEvent) => {
        const data = evt.active?.data.current;
        setDragging(true);
        setContext({
            fromDayPath: data?.dayPath,
            fromPath: data?.path,
            text: data?.text,
            complete: data?.complete,
        });
    };

    return (
        <DndContext
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            onDragCancel={() => setDragging(false)}
            sensors={smallScreen ? sensors : undefined}
        >
            {children}
        </DndContext>
    );
};
