import { create } from 'zustand';
import dayjs from 'dayjs';
import { DragContext } from './types';

interface DateState {
    date: dayjs.Dayjs;
    calendar: {
        open: boolean;
        context: {
            toData: Record<string, unknown>;
            fromData: Record<string, unknown>;
            copy: boolean;
        } | null;
    };
    setDate: (date: dayjs.Dayjs) => void;
    setCalendar: (value: object) => void;
}

export const useDateStore = create<DateState>()((set) => ({
    date: dayjs().startOf('day'),
    setDate: (date) => set(() => ({ date })),
    calendar: { open: false, context: null },
    setCalendar: (value) =>
        set((state) => ({ calendar: { ...state.calendar, ...value } })),
}));

interface DragState extends DragContext {
    setContext: (ctx: Partial<DragContext>) => void;
}

export const useDragStore = create<DragState>()((set) => ({
    fromPath: '',
    fromDayPath: '',
    toDayPath: '',
    toPath: '',
    text: '',
    complete: false,
    toPosition: -1,
    fromPosition: -1,
    setContext: (ctx) => set((state) => ({ ...state, ...ctx })),
}));
