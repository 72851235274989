import { useDroppable } from '@dnd-kit/core';
import React from 'react';
import {
    Container,
    ContainerProps,
    Divider,
    DividerProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface DroppableProps {
    id: string;
    data?: Record<string, unknown>;
    fillHeight?: boolean;
}

export const Droppable = (props: DroppableProps) => {
    const { setNodeRef, isOver } = useDroppable({
        id: props.id,
        data: props.data,
    });
    if (!props.fillHeight)
        return (
            <DividerStyled
                ref={setNodeRef}
                sx={{ visibility: isOver ? 'visible' : 'hidden' }}
            />
        );
    return (
        <ContainerStyled ref={setNodeRef}>
            <DividerStyled sx={{ visibility: isOver ? 'visible' : 'hidden' }} />
        </ContainerStyled>
    );
};

const ContainerStyled = styled(Container)<ContainerProps>(() => ({
    flexGrow: 1,
    margin: 0,
    padding: '0 !important',
}));

export const DividerStyled = styled(Divider)<DividerProps>(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
}));
