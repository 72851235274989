import React, { useState } from 'react';
import {
    Button,
    Card,
    CardActions,
    Dialog,
    DialogActions,
    DialogContent,
    Fab,
    LinearProgress,
    TextField,
    Theme,
    useMediaQuery,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { push, ref, set, update } from 'firebase/database';
import { useDatabase, useDatabaseObjectData } from 'reactfire';
import { Task } from '../utils/types';
import dayjs from 'dayjs';
import { DayHeader } from './DayHeader';
import TaskList from './tasks/TaskList';
import AddIcon from '@mui/icons-material/Add';

interface DayProps {
    userId?: string;
    date: dayjs.Dayjs | null;
    isToday?: boolean;
    style?: object;
}

const Day = (props: DayProps) => {
    if (!props.userId) return;
    const [inputValue, setInputValue] = useState('');
    const db = useDatabase();
    const dbKey = props.date?.format('YY_MM_DD') ?? 'backlog';
    const path = `${props.userId}/${dbKey}`;
    const dayRef = ref(db, path);
    const { status: dayStatus, data } =
        useDatabaseObjectData<Record<string, Task>>(dayRef);

    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md'),
    );
    const [open, setOpen] = React.useState(false);

    // Sort is still sometimes off from firebase db
    const ordered = Object.entries(data ?? {})
        .reduce<Task[]>((acc, [id, t]) => {
            acc.push({
                id,
                text: t.text,
                complete: t.complete,
                position: t.position,
            });
            return acc;
        }, [])
        .sort(
            (a, b) =>
                Number(a.complete) - Number(b.complete) ||
                a.position - b.position,
        );
    const repositionTasks = () => {
        if (!ordered) return;
        for (let i = 0; i < ordered.length; i++) {
            update(ref(db, path + `/${ordered[i].id}`), {
                position:
                    ordered[i].position === -999 ? ordered.length * 10 : i * 10,
            });
        }
    };

    if (dayStatus !== 'loading') {
        // reposition if some of the days don't have a position
        if (
            ordered?.some(
                (t) => t.position === undefined || t.position === -999,
            )
        )
            repositionTasks();
    }

    const addTask = async (value: string, complete: boolean) => {
        // reset all positions on new task add
        repositionTasks();
        if (Array.isArray(ordered) && ordered.length === 0)
            set(dayRef, [{ text: value, complete, position: 0 }]);
        else
            push(dayRef, {
                text: value,
                complete,
                position: (ordered?.length ?? 0) * 10,
            });
    };

    const onSubmit = () => {
        addTask(inputValue, false);
        setInputValue('');
        setOpen(false);
    };

    return (
        <Card
            elevation={3}
            style={{
                flexGrow: 1,
                overflowY: 'scroll',
                padding: 10,
                border: props.isToday ? `2px solid ${blueGrey[900]}` : '',
                display: 'flex',
                flexDirection: 'column',
                margin: '0.5em',
                ...(props.style ?? {}),
            }}
        >
            <DayHeader
                date={props.date}
                onAddTask={addTask}
                dayStatus={dayStatus}
                dayPath={path}
            />
            <div style={{ flexGrow: 1 }}>
                {dayStatus === 'loading' && <LinearProgress />}
                {dayStatus !== 'loading' && ordered && (
                    <TaskList
                        dayPath={path}
                        style={{ padding: 0, margin: 0 }}
                        items={ordered}
                        date={props.date}
                    />
                )}
            </div>
            <CardActions sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Fab
                    color="primary"
                    aria-label="add"
                    sx={{ display: { md: 'none', sm: 'inherit' } }}
                    onClick={() => setOpen(true)}
                >
                    <AddIcon />
                </Fab>
            </CardActions>
            {smallScreen && (
                <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                    <DialogContent>
                        <TextField
                            value={inputValue}
                            onChange={(v) => setInputValue(v.target.value)}
                            fullWidth
                            multiline
                            variant="outlined"
                            size="small"
                            autoFocus
                            id="new"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant={'contained'} onClick={onSubmit}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Card>
    );
};

export default Day;
